import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { skyBlueStrong } from './bedrock/SwColors';
import { SwInternalLink } from './bedrock/navigation/SwInternalLink';
import SwTypography from './bedrock/typography/SwTypography';

const SwCTABanner: React.FunctionComponent<SwCTABannerProps> = (props) => {
    const { bgColor = skyBlueStrong, target, title, actionLabel, ...rest } = props;

    const slug = target
        ? target.target.__typename === 'GraphCMS_Article'
            ? `${WEBSITE_MENU_ITEM[target.target.category?.usage.toLowerCase()].link}/${target.target.slug}`
            : `/${target.target.slug}`
        : null;

    return (
        <Box
            alignItems={'center'}
            bgcolor={bgColor}
            borderRadius={'8px'}
            display={'flex'}
            flexWrap={'wrap'}
            gap={2}
            justifyContent={'space-between'}
            p={3}
            {...rest}
        >
            <SwTypography bold={true} color={'white'} component={'p'} variant={'h3'}>
                {title}
            </SwTypography>
            {target && actionLabel ? (
                <SwInternalLink to={slug} variant={'secondary'}>
                    <ArrowRight />
                    {actionLabel}
                </SwInternalLink>
            ) : null}
        </Box>
    );
};

interface SwCTABannerProps extends BoxProps {
    bgColor?: string;
    title: string;
    actionLabel?: string;
    target?: {
        target: {
            slug: string;
            category?: {
                usage: string;
            };
            __typename: string;
        };
    };
}

export default SwCTABanner;
