import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { CDNClient } from '@lib/helpers/image';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { Customers } from '@app/home/customers';
import Welcome from '@app/home/welcome';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const HomePage = ({ data }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const { title, subtitle, promotionBanner } = data.page;

    const [firstSection, ...restOfSections] = data.page.sections;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={''}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <WebsiteSectionsLayout>
                        <Stack spacing={4}>
                            <SwContainer paddingInline={{ xs: '0', sm: '32px' }}>
                                <Welcome
                                    customerLogos={data.topPageCustomersLogo.nodes}
                                    industrySlugs={data.industryPages.nodes}
                                    promotionBanner={promotionBanner}
                                    section={data.page.sections[0]}
                                    subtitle={subtitle}
                                    title={title}
                                />
                            </SwContainer>
                            <SwContainer gap={32}>
                                <SwCmsRenderer content={firstSection} />
                                <SwCmsRenderer content={restOfSections} />
                            </SwContainer>
                        </Stack>

                        <Customers latestCustomerStories={data.latestCustomerStories.nodes} mobile={mobile} />
                    </WebsiteSectionsLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    >
        <link
            as={'image'}
            href={CDNClient.buildURL('/home/Island.png', {
                auto: 'format',
                ar: '4:3',
                h: 1875
            })}
            media={'(min-width: 960px)'}
            rel={'preload'}
        />
        <link
            as={'image'}
            href={CDNClient.buildURL('/home/Island.png', {
                auto: 'format',
                h: 800,
                ar: '3:4',
                fit: 'crop',
                crop: 'focalpoint',
                'fp-z': 1.1,
                'fp-x': 0.6,
                'fp-y': 1.8
            })}
            media={'(max-width: 599px)'}
            rel={'preload'}
        />
        <link
            as={'image'}
            href={CDNClient.buildURL('/home/Island.png', {
                auto: 'format',
                ar: '1',
                h: 1000,
                fit: 'crop',
                crop: 'focalpoint',
                'fp-z': 1.2,
                'fp-x': 0.6,
                'fp-y': 1.8
            })}
            media={'(min-width: 600px) and (max-width: 959px)'}
            rel={'preload'}
        />
    </Seo>
);

export const pageQuery = graphql`
    query getHomePageInformation($locale: GraphCMS_Locale, $id: String) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        latestCustomerStories: allGraphCmsCustomerStory(
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale } }
            sort: { order: DESC, fields: published }
            limit: 3
        ) {
            nodes {
                ...CustomerStoryCard_GraphCMS_CustomerStory
            }
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        enPage: graphCmsPage(slug: { eq: "home" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        industryPages: allGraphCmsPage(
            filter: {
                locale: { eq: $locale }
                slug: {
                    in: [
                        "finance/banks"
                        "finance/private-markets"
                        "finance/asset-owners"
                        "finance/asset-managers"
                        "business/manufacturing"
                        "business/grocery"
                        "business/professional-services"
                        "business/healthcare"
                        "business/consumer-goods"
                        "business/media-and-telecoms"
                        "business/energy-utilities-oil-and-gas"
                        "business/retail"
                    ]
                }
            }
        ) {
            nodes {
                id
                slug
                title
            }
        }
        topPageCustomersLogo: allGraphCmsCustomerLogo(
            filter: {
                customer: {
                    name: {
                        in: [
                            "Royal Canin"
                            "EDF"
                            "HP"
                            "Lacoste"
                            "Beaumanoir"
                            "World of Books"
                            "BPI France"
                            "Mirova"
                            "Eurazeo"
                            "Verdane"
                            "Mirova"
                            "Astanor"
                            "Eurazeo"
                            "Balderton"
                            "Swile"
                            "SSEnergy"
                        ]
                    }
                }
            }
        ) {
            nodes {
                ...Essentials_GraphCMS_CustomerLogo
            }
        }

        page: graphCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
            id
            remoteId
            title
            subtitle
            slug
            seo {
                ...Essentials_GraphCMS_Seo
            }
            promotionBanner {
                title
                actionLabel
                target {
                    target {
                        ... on GraphCMS_Article {
                            slug
                            category {
                                usage
                            }
                            __typename
                        }
                        ... on GraphCMS_Landing {
                            slug
                            __typename
                        }
                        ... on GraphCMS_Page {
                            slug
                            __typename
                        }
                    }
                }
            }
            sections {
                ... on GraphCMS_Section {
                    id
                    __typename
                    target
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_EmphasizedText
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_CustomerLogo
                                        ...Essentials_GraphCMS_LogoCard
                                        ...Essentials_GraphCMS_FeatureVideoCard
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default HomePage;
