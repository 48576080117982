import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';
import { SwFeatureImage } from '@lib/components';
import SwCTABanner from '@lib/components/SwCTABanner';
import { SwPageButtonLink } from '@lib/components/SwPageButtonLink';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwLinkBox, SwLinkOverlay } from '@lib/components/bedrock/navigation/SwLinkBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import { CDNClient } from '@lib/helpers/image';
import logo from '../../../assets/icons/logo-white.svg';

const StyledContainer = styled(Stack)`
    & img {
        transition: all 0.2s ease-in-out;
    }

    @supports selector(:has(*)) {
        &:hover:not(:has(a.page-link:hover)) img {
            scale: 1.05;
        }
    }

    @supports not selector(:has(*)) {
        &:hover img {
            scale: 1.05;
        }
    }
`;

const Welcome: React.FC<{
    subtitle: string;
    title: string;
    section: any;
    promotionBanner?: any;
    customerLogos: any[];
    industrySlugs: { slug: string; title: string }[];
}> = (props) => {
    const { t } = useTranslation();

    const financeIndustrySlugs = props.industrySlugs.filter(({ slug }) => slug.startsWith('finance'));
    const businessIndustrySlugs = props.industrySlugs.filter(({ slug }) => slug.startsWith('business'));

    return (
        <Stack height={'100%'} minWidth={0} spacing={4} width={'100%'}>
            <SwFeatureImage
                alt={'Sweep Journey Island'}
                aspectRatioImage={AspectRatios.landscape}
                bgcolor={colors.glacier[200]}
                loading={'eager'}
                mainContent={
                    <Stack alignItems={'start'} spacing={3}>
                        <Stack spacing={2}>
                            <Box
                                alignItems={'center'}
                                bgcolor={colors.sky[400]}
                                border={`1px solid ${colors.border}`}
                                borderRadius={'14px'}
                                display={'flex'}
                                height={64}
                                justifyContent={'center'}
                                width={64}
                            >
                                <img
                                    alt={'Sweep Logo'}
                                    height={39}
                                    src={logo}
                                    style={{ objectFit: 'contain' }}
                                    width={27}
                                />
                            </Box>
                            <SwTypography component={'h1'} fontSize={40} variant={'h3'}>
                                {props.title}
                            </SwTypography>
                            <SwTypography color={colors.text.secondary} component={'p'} variant={'body2'}>
                                {props.subtitle}
                            </SwTypography>
                        </Stack>
                        <SwInternalLink size={'large'} to={WEBSITE_MENU_ITEM.getInTouch.link}>
                            {t('get_in_touch')}
                        </SwInternalLink>
                    </Stack>
                }
                mainContentWidth={482}
                srcSet={{
                    xs: {
                        src: CDNClient.buildURL('/home/Island.png', {
                            auto: 'format',
                            h: 800,
                            ar: '3:4',
                            fit: 'crop',
                            crop: 'focalpoint',
                            'fp-z': 1.1,
                            'fp-x': 0.6,
                            'fp-y': 1.8
                        }),
                        height: 552,
                        width: 412
                    },
                    sm: {
                        src: CDNClient.buildURL('/home/Island.png', {
                            auto: 'format',
                            ar: '1',
                            h: 1000,
                            fit: 'crop',
                            crop: 'focalpoint',
                            'fp-z': 1.2,
                            'fp-x': 0.6,
                            'fp-y': 1.8
                        }),
                        height: 754,
                        width: 754
                    },
                    md: {
                        src: CDNClient.buildURL('/home/Island.png', {
                            auto: 'format',
                            ar: '4:3'
                        }),
                        height: 1009,
                        width: 1009
                    }
                }}
            />
            {props.promotionBanner ? <SwCTABanner {...props.promotionBanner} /> : null}
            <SwPaper bgcolor={colors.surface.subdued} p={1}>
                <SwGrid columns={{ xs: 1, sm: 2 }} gap={8}>
                    <SwPaper p={0}>
                        <StyledContainer>
                            <SwLinkBox>
                                <Stack p={3}>
                                    <Stack spacing={1}>
                                        <SwTypography bold={true} component={'h2'} variant={'h4'}>
                                            <SwLinkOverlay to={WEBSITE_MENU_ITEM.business.link}>
                                                {t('home_page.corporate_card_title')}
                                            </SwLinkOverlay>
                                        </SwTypography>
                                        <SwTypography color={colors.text.secondary}>
                                            {t('home_page.corporate_card_subtitle')}
                                        </SwTypography>
                                    </Stack>
                                    <SwLine />
                                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                        {(
                                            [
                                                'corporates',
                                                'supplyChain',
                                                'compliance',
                                                'esg',
                                                'catalogue',
                                                'csrd'
                                            ] as (keyof typeof WEBSITE_MENU_ITEM)[]
                                        ).map((name, index) => (
                                            <SwPageButtonLink key={index} pageName={name} />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Box bgcolor={colors.crop[400]} style={{ aspectRatio: '1/0.8' }}>
                                    <Box
                                        alt={'Corporate building'}
                                        component={'img'}
                                        loading={'lazy'}
                                        src={CDNClient.buildURL('/home/corporate-building.png', {
                                            ar: '1:0.8',
                                            fit: 'crop',
                                            crop: 'focalpoint',
                                            auto: 'format',
                                            'fp-z': 1.3,
                                            'fp-y': 0.38,
                                            h: 1000
                                        })}
                                    />
                                </Box>
                            </SwLinkBox>
                            <Box position={'relative'}>
                                <SwPaper
                                    borderBottom={0}
                                    borderLeft={0}
                                    borderRight={0}
                                    height={'100%'}
                                    p={3}
                                    style={{ marginTop: '-16px ' }}
                                    width={'100%'}
                                >
                                    <SwTypography bold={true}> {t('home_page.business_card_title')}</SwTypography>
                                    <SwLine />
                                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                        {businessIndustrySlugs.map((page, index) => (
                                            <SwPageButtonLink key={index} name={page.title} to={`/${page.slug}`} />
                                        ))}
                                    </Stack>
                                </SwPaper>
                            </Box>
                        </StyledContainer>
                    </SwPaper>
                    <SwPaper p={0} position={'relative'}>
                        <StyledContainer>
                            <SwLinkBox>
                                <Stack p={3}>
                                    <Stack spacing={1}>
                                        <SwTypography bold={true} component={'h2'} variant={'h4'}>
                                            <SwLinkOverlay to={WEBSITE_MENU_ITEM.finance.link}>
                                                {t('home_page.financed_card_title')}
                                            </SwLinkOverlay>
                                        </SwTypography>
                                        <SwTypography color={colors.text.secondary}>
                                            {t('home_page.financed_card_subtitle')}
                                        </SwTypography>
                                    </Stack>
                                    <SwLine />
                                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                        {(
                                            [
                                                'financedEmissions',
                                                'corporates',
                                                'compliance',
                                                'esg'
                                            ] as (keyof typeof WEBSITE_MENU_ITEM)[]
                                        ).map((name, index) => (
                                            <SwPageButtonLink key={index} pageName={name} />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Box bgcolor={colors.glacier[400]} style={{ aspectRatio: '1/0.8' }}>
                                    <Box
                                        alt={'Financial building'}
                                        component={'img'}
                                        loading={'lazy'}
                                        src={CDNClient.buildURL('/home/financial.png', {
                                            ar: '1:0.8',
                                            fit: 'crop',
                                            crop: 'focalpoint',
                                            auto: 'format',
                                            'fp-z': 1.15,
                                            'fp-y': 0.465,
                                            h: 1000
                                        })}
                                    />
                                </Box>
                            </SwLinkBox>
                            <Box position={'relative'}>
                                <SwPaper
                                    borderBottom={0}
                                    borderLeft={0}
                                    borderRight={0}
                                    p={3}
                                    style={{ marginTop: '-16px', paddingBottom: 'calc(16px + 24px)' }}
                                    width={'100%'}
                                >
                                    <SwTypography bold={true}>{t('home_page.finance_card_title')}</SwTypography>
                                    <SwLine />
                                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                        {financeIndustrySlugs.map((page, index) => (
                                            <SwPageButtonLink key={index} name={page.title} to={`/${page.slug}`} />
                                        ))}
                                    </Stack>
                                </SwPaper>
                            </Box>
                        </StyledContainer>
                    </SwPaper>
                </SwGrid>
            </SwPaper>
        </Stack>
    );
};

export default Welcome;
