import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwFeatureImage } from '@lib/components';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { colors } from '@lib/components/bedrock/fundations';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import { CDNClient } from '@lib/helpers/image';

export const Customers: React.FunctionComponent<SwCustomersSectionProps> = (props) => {
    const { latestCustomerStories } = props;

    const { t } = useTranslation();

    return (
        <SwContainer gap={16}>
            <SwFeatureImage
                alt={'Sweep Journey Island'}
                aspectRatioImage={{
                    lg: '17/9',
                    md: AspectRatios.golden,
                    sm: AspectRatios.square,
                    xs: AspectRatios.square
                }}
                loading={'eager'}
                mainContent={
                    <Stack spacing={2}>
                        <SwTypography
                            bold={true}
                            color={colors.text.secondary}
                            component={'h2'}
                            uppercase={true}
                            variant={'body2'}
                        >
                            {t('customer_stories')}
                        </SwTypography>
                        <SwTypography component={'p'} variant={'h3'}>
                            {t('home_page.customers_section_subtitle')}
                        </SwTypography>
                    </Stack>
                }
                mainContentWidth={418}
                secondaryContent={
                    <SwGrid columns={{ xs: 1, sm: 3 }} gap={8}>
                        {latestCustomerStories.map((story) => (
                            <SwRecordCard
                                key={story.id}
                                meta={
                                    <GatsbyImage
                                        alt={story.company.logo.alt}
                                        image={story.company.logo.gatsbyImageData}
                                        objectFit={'contain'}
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                }
                                record={{
                                    ...story,
                                    coverImage: {
                                        alt: story.company.images?.[0]?.alt,
                                        gatsbyImageData: story.company.images?.[0]?.gatsbyImageData
                                    }
                                }}
                                titleHeading={'h3'}
                            />
                        ))}
                    </SwGrid>
                }
                srcSet={{
                    xs: {
                        src: CDNClient.buildURL('/home/customers.jpg', {
                            auto: 'format',
                            h: 800,
                            ar: '1',
                            fit: 'crop',
                            crop: 'faces',
                            'fp-z': 1.5
                        }),
                        height: 412,
                        width: 412
                    },
                    sm: {
                        src: CDNClient.buildURL('/home/customers.jpg', {
                            auto: 'format',
                            ar: '1.6180:1',
                            h: 1000,
                            fit: 'crop',
                            crop: 'faces',
                            'fp-z': 1.5
                        }),
                        height: 819,
                        width: 819
                    },
                    md: {
                        src: CDNClient.buildURL('/home/customers.jpg', {
                            auto: 'format',
                            ar: '17:9',
                            crop: 'faces',
                            fit: 'crop',
                            h: 1875
                        }),
                        height: 1009,
                        width: 1009
                    }
                }}
            />
            <Box alignItems={'start'} display={'flex'} flexDirection={'column'}>
                <SwInternalLink size={'large'} to={WEBSITE_MENU_ITEM.stories.link} variant={'secondary'}>
                    {t('more_customer_stories')} <ArrowRight />
                </SwInternalLink>
            </Box>
        </SwContainer>
    );
};

export interface SwCustomersSectionProps {
    mobile: boolean;
    latestCustomerStories: any[];
}
